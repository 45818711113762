import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['form', 'startDate', 'duration', 'reason', 'submitButton', 'csrf', 'validationAlert', 'resume'];

    static values = {
        submitUrl: String,
        resumeUrl: String
    };

    #successTimeout = 5000;
    #errorTimeout = 6000;
    #messages = {
        resumeSuccess: 'Ihre Anfrage wird jetzt verarbeitet. Bitte haben Sie etwas Geduld, bis die Änderungen vollständig übernommen werden',
        resumeError: 'Die Pausierung konnte nicht entfernt werden, Versuchen Sie es bitte später erneut. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an den Support',
        pauseSuccess: 'Die Pausierung wurde erfolgreich beantragt und wird verarbeitet',
        error: 'Versuchen Sie es bitte später erneut. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an den Support.'
    };

    async submitPauseForm(event) {
        event.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        const sourceInput = document.getElementById('pause-action-source');
        let formData = {
            duration: this.durationTarget.value,
            source: sourceInput.value
        };
        if (this.reasonTarget.value !== 'Bitte auswählen') {
            formData = {...formData, reason: this.reasonTarget.value};
        }

        const spinnerEl = this.submitButtonTarget.querySelector('.spinner-border');
        try {
            this.submitButtonTarget.setAttribute('disabled', '');
            spinnerEl.classList.toggle('d-none');
            const response = await fetch(
                this.submitUrlValue, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': this.csrfTarget.value,
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    body: JSON.stringify(formData)
                });

            const json = await response.json();
            if (json.ok) {
                this.submitButtonTarget.removeAttribute('disabled');
                spinnerEl.classList.toggle('d-none');
                jQuery('#pauseModal').modal('hide');
                this.showFeedbackAlert(this.#messages.pauseSuccess, true);
                setTimeout(() => {
                    window.location.reload();
                }, this.#successTimeout);
            } else {
                this.submitButtonTarget.removeAttribute('disabled');
                spinnerEl.classList.toggle('d-none');
                this.showValidationErrorAlert();
            }
        } catch (error) {
            this.submitButtonTarget.removeAttribute('disabled');
            spinnerEl.classList.toggle('d-none');
            this.showValidationErrorAlert();
        }
    }

    async resumeContract(event) {
        try {
            const response = await fetch(
                this.resumeUrlValue,
                {
                    method: 'POST',
                    body: JSON.stringify({contractId: event.params.id})
                }
            );

            const json = await response.json();
            this.showFeedbackAlert(
                json.ok ? this.#messages.resumeSuccess : this.#messages.resumeError,
                json.ok
            );
            setTimeout(() => {
                window.location.href = window.location.pathname;
            }, this.#successTimeout);
        } catch (error) {
            this.showFeedbackAlert(this.#messages.resumeError, false);
        }
    };

    validateForm() {
        let isValid = true;
        if (!this.durationTarget.value || this.durationTarget.value === 'Bitte auswählen') {
            this.showFieldError(this.durationTarget, 'Bitte wählen Sie eine Pausenlänge');
            isValid = false;
        }

        return isValid;
    }

    showFieldError(field, message) {
        const existingError = field.nextElementSibling;
        if (existingError?.classList.contains('invalid-feedback')) {
            existingError.remove();
        }
        field.classList.add('is-invalid');
        const errorDiv = document.createElement('div');
        errorDiv.classList.add('invalid-feedback');
        errorDiv.textContent = message;
        field.parentNode.appendChild(errorDiv);
    }

    clearFieldError(event) {
        const field = event.target;
        field.classList.remove('is-invalid');
        const errorMessage = field.nextElementSibling;
        if (errorMessage?.classList.contains('invalid-feedback')) {
            errorMessage.remove();
        }
    }

    showValidationErrorAlert() {
        this.validationAlertTarget.classList.toggle('d-none');
        this.validationAlertTarget.textContent = this.#messages.error;
    }

    showFeedbackAlert(message, isSuccess) {
        const alertEl = document.getElementById('pause-contract-feedback-alert');
        const alertClass = isSuccess ? 'alert-success' : 'alert-danger';

        alertEl.classList.add(alertClass);
        if (!isSuccess) {
            alertEl.classList.add('alert-w');
        }
        alertEl.classList.toggle('d-none');
        alertEl.textContent = message;
    };
}