import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['content']
    active = false;


    connect() {
        document.addEventListener("click", this.handleOutsideClick.bind(this));
        document.addEventListener('keydown', this.handleKeyPress.bind(this));
    }

    disconnect() {
        document.removeEventListener('click', this.handleOutsideClick.bind(this));
        document.removeEventListener('keydown', this.handleKeyPress.bind(this));
    }

    toggle() {
        this.contentTarget.classList.toggle("tw-hidden");
        this.active = !this.active;
    }

    handleOutsideClick(event) {
        if (this.element.contains(event.target) === false) {
            this.contentTarget.classList.add("tw-hidden");
        }
    }

    handleKeyPress(event) {
        if (event.key === 'Escape') {
            if (this.active) {
                this.contentTarget.classList.add("tw-hidden");
                this.active = false;
            }
        }
    }
}