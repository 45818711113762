import {Controller} from "@hotwired/stimulus";
import {getComponent} from '@symfony/ux-live-component';

export default class extends Controller {
    async initialize() {
        this.component = await getComponent(this.element);
    }

    connect() {
        document.addEventListener('favorite:added', this.handleFavorite.bind(this));
        document.addEventListener('favorite:removed', this.handleFavorite.bind(this));
    }

    disconnect() {
        document.removeEventListener('favorite:added', this.handleFavorite.bind(this));
        document.removeEventListener('favorite:removed', this.handleFavorite.bind(this));
    }

    handleFavorite(event) {
        const joboffererProfileId = event.detail.joboffererProfileId;
        const thisJobofferer = this.component.valueStore.get('joboffererProfile');
        const isFavorite = this.component.valueStore.get('isFavorite');

        const shouldUpdate =
            (joboffererProfileId === thisJobofferer)
            && (
                (isFavorite && event.type === 'favorite:removed')
                || (!isFavorite && event.type === 'favorite:added')
            );

        if (shouldUpdate) {
            const liveAction = event.type === 'favorite:added' ? 'addFavorite' : 'removeFavorite';
            this.component.action(liveAction);
        }
    }
}
