import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        window.addEventListener('pageshow', this.handleBack.bind(this));
    }

    disconnect() {
        window.removeEventListener('pageshow', this.handleBack.bind(this));
    }

    handleBack() {
        fetch(
            '/_/api/reg/check-done', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate'
                }
            })
            .then(response => response.json())
            .then(data => {
                if (!data['reg_status']) {
                    return;
                }
                window.location.href = data['url'];
            })
            .catch(e => console.error(e));
    }


}
