import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["modal"];
    active = false;

    connect() {
        document.addEventListener('keydown', this.handleKeyPress.bind(this));
        document.addEventListener('click', this.handleClick.bind(this));
    }

    disconnect() {
        document.removeEventListener('keydown', this.handleKeyPress.bind(this));
    }


    show() {
        this.modalTarget.classList.add("flex");
        this.modalTarget.classList.remove("hidden");
        this.active = true;
    }

    hide() {
        this.modalTarget.classList.add("hidden");
        this.modalTarget.classList.remove("flex");
        this.active = false;
    }

    handleClick(event) {
        if (event.target.matches('[data-role=modal-trigger]')) {
            this.show();
        }

        if (event.target.closest('[data-role=modal-close]')) {
            const modal = event.target.closest('[data-role=modal-overlay]');
            this.hide();
        }
    }

    handleKeyPress(event) {
        if (event.key === 'Escape') {
            if (this.active) {
                this.hide();
            }
        }
    }
}