require('intl');
require('popper.js');
require('bootstrap');
require('../../node_modules/mdbootstrap/js/mdb');
require('moment');
require('moment/locale/de');
require('eonasdan-bootstrap-datetimepicker');
require('lightbox2');
require('intl/locale-data/jsonp/de-DE');

import {startStimulusApp} from "@symfony/stimulus-bridge";
import controllers from "./stimulusControllers";


export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
    true,
    /\.[jt]sx?$/
));

controllers.forEach((controller) => {
    const [identifier, controllerClass] = Object.entries(controller)[0];
    app.register(identifier, controllerClass)
})

// One namespace for all functions that need to be globally available (e.g. for onclick= calls)
if (window.appFunctions === undefined) {
    window.appFunctions = {};
}

// Stupid workaround for https://mdbootstrap.com/support/jquery/bscustomfileinput-is-not-defined/
window.bsCustomFileInput = {
    init: () => null
};

const jQuery = require('jquery');
require('jquery.browser');
const jstz = require('jstimezonedetect');
const jsCookie = require('js-cookie');
const noUiSlider = require('nouislider');
const kjua = require('kjua');

global.$ = global.jQuery = jQuery;
global.noUiSlider = noUiSlider;
global.kjua = kjua;

window.addEventListener('DOMContentLoaded', function () {
    (function (jQuery) {

        jQuery(function () {
            var tz = jstz.determine();
            jsCookie.set('go-gastro_client_timezone', tz.name(), {secure: true, sameSite: 'lax'});
        });

        // Ensures that the old Chromium based "Samsung Internet" browser on Android 4 shows the "Menu" item in the navbar
        if (jQuery.browser.android && jQuery.browser.webkit && jQuery.browser.versionNumber < 60) {
            jQuery('#navbar').removeClass('navbar-expand-lg');
            jQuery('#outermost-main-container').addClass('workaround-nav-samsung-internet-on-android-4');
        }

        jQuery('a').removeClass('waves-effect waves-light');
        jQuery('button').removeClass('waves-effect waves-light');
        jQuery('btn').removeClass('waves-effect waves-light');

        jQuery('.remove-item-trigger').on('click', function () {
            var triggerElement = jQuery(this);
            var targetElement = triggerElement.closest('.remove-item-container').find('.remove-item-target');
            if (triggerElement.is(':checked')) {
                targetElement.css('filter', 'opacity(30%)');
            } else {
                targetElement.css('filter', 'opacity(100%)');
            }
        });

        jQuery('.teaser-container:visible').html(
            '<iframe width="100%" height="200" src="https://www.youtube-nocookie.com/embed/4wUhA9Qr3dA?rel=0&amp;showinfo=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>'
        );

        const deactivateAllWeekplanDaysandtimesButtonSetState = function () {
            setTimeout(function () {
                var state = jQuery('.weekplan-dayandtime-checkbox:checked')[0];
                jQuery('[id^="deactivate-all-weekplan-daysandtimes-button"]').attr('style', state ? '' : 'visibility: hidden;')
            }, 1);
        }
        jQuery('.weekplan-dayandtime-checkbox, [id*="activate-all-weekplan-daysandtimes-button"]').on('click', deactivateAllWeekplanDaysandtimesButtonSetState);
        deactivateAllWeekplanDaysandtimesButtonSetState();

        const $root = jQuery('html, body');
        jQuery(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();

            try {
                $root.animate({
                    scrollTop: jQuery(jQuery.attr(this, 'href')).offset().top
                }, 500);
            } catch (e) {
            }
        });
    })(jQuery);
});

window.appFunctions.copyValueFromInputElement = function (inputElementId, successMessageElementId) {
    const $input = document.getElementById(inputElementId);
    $input.select();
    document.execCommand('copy');
    document.getElementById(successMessageElementId).innerHTML = "Erfolgreich kopiert!";
}

if (!String.prototype.includes) {
    String.prototype.includes = function (search, start) {
        'use strict';

        if (search instanceof RegExp) {
            throw TypeError('first argument must not be a RegExp');
        }
        if (start === undefined) {
            start = 0;
        }
        return this.indexOf(search, start) !== -1;
    };
}
