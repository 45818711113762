import MenuController
    from '../../src/JanusHercules/RecurrentJobsSearch/Presentation/Resources/stimulus_controllers/menu_controller.js';
import ActionBarController
    from '../../src/JanusHercules/RecurrentJobsSearch/Presentation/Resources/stimulus_controllers/action_bar_controller.js';
import RecurrentJobsSearchController
    from '../../src/JanusHercules/RecurrentJobsSearch/Presentation/Resources/stimulus_controllers/recurrent_jobs_search_controller';
import FavoriteController
    from '../../src/JanusHercules/RecurrentJobsSearch/Presentation/Resources/stimulus_controllers/favorite_controller';
import PopoverController
    from '../../src/JanusHercules/Shared/Presentation/Resources/stimulus_controller/popover_controller';
import ModalController
    from '../../src/JanusHercules/Shared/Presentation/Resources/stimulus_controller/modal_controller';
import CollapseController
    from '../../src/JanusHercules/JobseekerRegistration/Presentation/Resources/stimulus_controllers/collapse_controller';
import DropdownController
    from '../../src/JanusHercules/Shared/Presentation/Resources/stimulus_controller/dropdown_controller';
import TruncateController
    from '../../src/JanusHercules/Shared/Presentation/Resources/stimulus_controller/truncate_controller';
import ExtendedApplicationUploadFileController
    from '../../src/JanusHercules/ExtendedApplication/Presentation/Resources/stimulus_controllers/upload_files_controller';
import ComboboxController
    from '../../src/JanusHercules/Shared/Presentation/Resources/stimulus_controller/combobox_controller';
import RegisterController
    from '../../src/JanusHercules/JobseekerRegistration/Presentation/Resources/stimulus_controllers/register_controller';
import JobOffererRegisterController
    from '../../src/JanusHercules/././JoboffererRegistration/Presentation/Resources/stimulus_controllers/register_controller';
import QuickRegistrationController
    from '../../src/JanusHercules/JobseekerQuickRegistration/Presentation/Resources/stimulus_controllers/quickregistration_controller';
import ActivateJobradarController
    from "../../src/JanusHercules/RecurrentJobsSearch/Presentation/Resources/stimulus_controllers/activate_jobradar_controller";
import PauseMemberShipFormController from '../../src/JanusHercules/Membership/Presentation/Resources/stimulus_controllers/pause_controller'
import CancelMemberShipFormController from '../../src/JanusHercules/Membership/Presentation/Resources/stimulus_controllers/cancel_controller'

// register new stimulus controller as follow: { identifier: controllerClass }
const controllers = [
    {'recurrent-search-menu': MenuController},
    {'recurrent-search-actionbar': ActionBarController},
    {'recurrent-search-main': RecurrentJobsSearchController},
    {'popover': PopoverController},
    {'recurrent-search-favorite': FavoriteController},
    {'modal': ModalController},
    {'collapse': CollapseController},
    {'dropdown': DropdownController},
    {'truncate': TruncateController},
    {'combobox': ComboboxController},
    {'register': RegisterController},
    {'upload-files': ExtendedApplicationUploadFileController},
    {'jobofferer-register':JobOffererRegisterController},
    {'upload-files': ExtendedApplicationUploadFileController},
    {'pause-contract':PauseMemberShipFormController},
    {'cancel-contract': CancelMemberShipFormController},
    {'quick-registration': QuickRegistrationController},
    {'activate-jobradar': ActivateJobradarController}
];

export default controllers;
