import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['form', 'email', 'emailRepeated', 'alert', 'alertMessage', 'submitButton', 'spinner','agb'];
    static values = {
        postUrl: String
    };

    connect() {
        this.formTarget.addEventListener('submit', this.onSubmit.bind(this));
    }

    onSubmit(event) {
        event.preventDefault();
        this.resetStyles();
        this.hideAlert();
        if (this.validateForm()) {
            this.submitForm();
        }
    }

    validateForm() {
        const email = this.emailTarget.value;
        const emailRepeated = this.emailRepeatedTarget.value;
        const acceptedTerms = this.agbTarget.checked
        let errorMessages = [];
        console.log(acceptedTerms);
        if (!this.isValidEmail(email)) {
            errorMessages.push('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
            this.applyErrorStyle(this.emailTarget);
        }

        if (email !== emailRepeated) {
            errorMessages.push('Die E-Mail-Adressen stimmen nicht überein.');
            this.applyErrorStyle(this.emailRepeatedTarget);
        }

        if(!acceptedTerms){
            errorMessages.push('Bitte akzeptiere die AGB.');
            this.applyErrorStyle(this.agbTarget);
        }

        if (errorMessages.length > 0) {
            this.showAlert(errorMessages);
            return false;
        }
        return true;
    }

    isValidEmail(email) {
        const regExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return regExp.test(email);
    }

    applyErrorStyle(inputElement) {
        inputElement.classList.remove('is-valid');
        inputElement.classList.add('is-invalid');
    }

    resetStyles() {
        this.emailTarget.classList.remove('is-invalid', 'is-valid');
        this.emailRepeatedTarget.classList.remove('is-invalid', 'is-valid');
    }

    showAlert(messages) {
        this.alertTarget.classList.remove('d-none');
        this.alertMessageTarget.innerHTML = messages.join('<br>');
    }

    hideAlert() {
        this.alertTarget.classList.add('d-none');
        this.alertMessageTarget.textContent = '';
    }

    async submitForm() {
        const formData = new FormData(this.formTarget);
        const acceptedTerms = this.agbTarget.checked;
        formData.set('acceptedTermsAndConditions', acceptedTerms)
        try {
            this.submitButtonTarget.disabled = true;
            this.spinnerTarget.classList.remove('d-none');
            const response = await fetch(
                this.postUrlValue,
                {
                    method: 'POST',
                    body: formData,
                });
            const json = await response.json();
            if (response.ok) {
                window.location.href = json.data.url;
            } else {
                this.showAlert([json.message || 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.']);
            }
            this.submitButtonTarget.disabled = false;
            this.spinnerTarget.classList.add('d-none');
        } catch (error) {
            this.showAlert(['Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.']);
            this.submitButtonTarget.disabled = false;
            this.spinnerTarget.classList.add('d-none');
        }
    }
}
