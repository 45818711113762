import {Controller} from "@hotwired/stimulus";


export default class extends Controller {
    static targets = ['selectionCount', 'actionBar']
    selectedJobs = [];

    connect() {
        document.addEventListener("select:update", this.handleUpdateSelection.bind(this));
        window.addEventListener("resize", this.handleResize.bind(this));
    }

    disconnect() {
        document.removeEventListener("select:update", this.handleUpdateSelection.bind(this));
        window.removeEventListener("resize", this.handleResize.bind(this));
    }

    handleUpdateSelection(event) {
        const {selected, recurrentJobId} = event.detail;
        const maxBatchNumber = this.actionBarTarget.dataset.maxBatchNumber;

        if (selected) {
            this.selectedJobs = [...this.selectedJobs, event.detail];
            if (this.selectedJobs.length >= maxBatchNumber) {
                document.dispatchEvent(new CustomEvent('max-select', {detail: {isMax: true}}));
            }
        } else {
            if (this.selectedJobs.length === maxBatchNumber) {
                this.selectedJobs = this.selectedJobs.filter(job => job.recurrentJobId !== recurrentJobId);
                document.dispatchEvent(new CustomEvent('max-select', {detail: {isMax: false}}));
            }
            this.selectedJobs = this.selectedJobs.filter(job => job.recurrentJobId !== recurrentJobId);
        }

        const totalSelected = this.selectedJobs.length;
        if (totalSelected > 0) {
            this.actionBarTarget.classList.remove('tw-hidden');
            this.actionBarTarget.classList.add('tw-flex');
        } else {
            this.actionBarTarget.classList.remove('tw-flex');
            this.actionBarTarget.classList.add('tw-hidden');
        }

        this.selectionCountTarget.innerText = totalSelected + ' ausgewählt';
    }

    clearAll() {
        document.dispatchEvent(new CustomEvent('select:clear'));
        this.selectedJobs = [];

        this.actionBarTarget.classList.remove('tw-flex');
        this.actionBarTarget.classList.add('tw-hidden');
    }

    sendSelected() {
        const form = document.querySelector('#multiple-message-form');
        form.submit();
    }

    handleResize() {
        if (window.innerWidth <= 768) {
            this.clearAll();
        }
    }

    toggleAllSelection(event) {
        const checked = event.target.checked;
        if (checked) {
            this.selectedJobs = [];
            const jobs = document.querySelectorAll('[data-recurrent-search-main-target="checkbox"]')
            if (jobs.length > 0) {
                jobs.forEach(job => {
                    this.selectedJobs = [
                        ...this.selectedJobs,
                        {
                            recurrentJobId: job.dataset.recurrentJobId,
                            joboffererProfileId: job.dataset.joboffererProfileId
                        }
                    ];
                })
            }
            document.dispatchEvent(new CustomEvent('select:all'));
            this.selectionCountTarget.innerText = 'Alle ausgewählt';
        } else {
            this.clearAll();
        }
    }
}
