import {Controller} from '@hotwired/stimulus';


export default class extends Controller {
    static targets = ['checkbox', 'cancelBtn', 'cancelAlert'];

    static values = {
        submitUrl: String,
        cancelUrl: String,
        currentlyPaused: Boolean,
    };
    #message = 'Die Kündigung ist erfolgreich';

    async submitQuestionnaire(event) {
        event.preventDefault();
        const selectedReasons = this.checkboxTargets
            .filter(checkbox => checkbox.checked)
            .map(checkbox => parseInt(checkbox.value));

        if (selectedReasons.length === 0) {
            jQuery('#cancellationQuestionerModal').modal('hide');
            const modalToShow = this.currentlyPausedValue ? '#cancellation-confirmation-modal' : '#cancellation-modal';
            jQuery(modalToShow).modal('show');
            return;
        }

        try {
            const res = await fetch(this.submitUrlValue, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedReasons)
            });
            const json = await res.json();
            if (json.ok) {
                // https://getbootstrap.com/docs/4.6/components/modal/#via-javascript
                jQuery('#cancellationQuestionerModal').modal('hide');
                const modalToShow = this.currentlyPausedValue ? '#cancellation-confirmation-modal' : '#cancellation-modal';
                jQuery(modalToShow).modal('show');
            }
        } catch (error) {
            jQuery('#cancellationQuestionerModal').modal('hide');
            const modalToShow = this.currentlyPausedValue ? '#cancellation-confirmation-modal' : '#cancellation-modal';
            jQuery(modalToShow).modal('show');

        }
    }

    async cancelContract() {
        const spinnerEl = this.cancelBtnTarget.querySelector('.spinner-border');
        this.cancelAlertTarget.classList.add('d-none');
        const contractId = this.cancelBtnTarget.dataset.contractId;

        try {
            spinnerEl.classList.toggle('d-none');
            this.cancelBtnTarget.setAttribute('disabled', '');
            const res = await fetch(
                this.cancelUrlValue,
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify({contractId: contractId})
                }
            );

            const json = await res.json();
            if (json.ok) {
                spinnerEl.classList.toggle('d-none');
                this.cancelBtnTarget.removeAttribute('disabled');
                jQuery('#cancellation-confirmation-modal').modal('hide');
                this.showFeedBackAlert();
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
            } else {
                spinnerEl.classList.toggle('d-none');
                this.cancelBtnTarget.removeAttribute('disabled');
                this.showErrorAlert();
            }
        } catch (err) {
            spinnerEl.classList.toggle('d-none');
            this.cancelBtnTarget.removeAttribute('disabled');
            this.showErrorAlert();
        }
    }

    showErrorAlert() {
        this.cancelAlertTarget.classList.toggle('d-none');
        this.cancelAlertTarget.textContent = 'Versuchen Sie es bitte später erneut. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an den Support.';
    }

    showFeedBackAlert() {
        const alertEl = document.getElementById('cancel-contract-feedback-alert');
        alertEl.classList.toggle('d-none');
        alertEl.classList.add('alert-success');
        alertEl.textContent = this.#message;
    }
}