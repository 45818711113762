import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["dropdown"];
    active = false;

    connect() {
        document.addEventListener("click", this.handleOutsideClick.bind(this));
        document.addEventListener('keydown', this.handleKeyPress.bind(this));
    }

    disconnect() {
        document.removeEventListener('click', this.handleOutsideClick.bind(this));
        document.removeEventListener('keydown', this.handleKeyPress.bind(this));
    }

    toggle() {
        if (this.active) {
            this.hide();
        } else {
            this.show();
        }
    }

    show() {
        this.dropdownTarget.classList.add("tw-flex");
        this.dropdownTarget.classList.remove("tw-hidden");
        this.active = true;
    }

    hide() {
        this.dropdownTarget.classList.add("tw-hidden");
        this.dropdownTarget.classList.remove("tw-flex");
        this.active = false;
    }

    handleOutsideClick(event) {
        if (this.active && this.element.contains(event.target) === false) {
            this.hide();
        }
    }

    handleKeyPress(event) {
        if (event.key === 'Escape') {
            if (this.active) {
                this.hide();
            }
        }
    }
}

