import {Controller} from '@hotwired/stimulus';

export default class extends Controller {

    isNotEmpty(value) {
        const $textRegex = /^(?![0-9._])(?!.*[0-9._]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9_ ]+$/;
        return $textRegex.test(value) && Boolean(value) && value.length > 2;
    }

    isEmail(value) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(value);
    }

    static targets = [
        'modal',
        'quickRegistrationForm',
        'errorMessageWrapper',
        'quickRegistrationEmailAddress',
        'quickRegistrationFirstName',
        'quickRegistrationLastName',
        'quickRegistrationServiceTerms',
        'quickRegistrationSubmitMessage'
    ];
    active = false;
    jqueryExists = true;
    titleConfirmationElement = this.modalTarget.querySelector('#quick-registration-title-confirmation');
    titleSearchtermElement = this.modalTarget.querySelector('#quick-registration-title-searchterm');
    googleButtonElement = this.modalTarget.querySelector('#quick-registration-google-button');
    formContentElement = this.quickRegistrationFormTarget.querySelector('#quick-registration-form-content');

    connect() {
        document.addEventListener('click', this.handleModalOpen.bind(this));
        this.modalTarget.querySelector('#quick-registration-button').addEventListener('click', this.handleSubmitClick.bind(this));
        this.modalTarget.querySelector('#modal-dismiss').addEventListener('click', this.handleModalClose.bind(this));

        // Handle unlikely case of missing jQuery: Do not display button
        if (typeof window.jQuery === 'undefined') {
            this.jqueryExists = false;

            /*
            document.querySelector('[data-role="modal-trigger"]').style.display = 'none';
            return false;
             */
        }
    }

    handleModalClose(event) {
        this.quickRegistrationSubmitMessageTarget.classList.add('d-none');
        this.formContentElement.classList.remove('d-none');
        this.modalTarget.querySelector('.modal-body').classList.remove('text-center');
        this.googleButtonElement.classList.remove('d-none');
        this.titleConfirmationElement.classList.add('d-none');
        this.titleSearchtermElement.classList.remove('d-none');
        this.hide();
    }

    handleSubmitClick(event) {
        let emailAddressField = this.quickRegistrationEmailAddressTarget;
        let firstNameField = this.quickRegistrationFirstNameTarget;
        let lastNameField = this.quickRegistrationLastNameTarget;
        let serviceTermsField = this.quickRegistrationServiceTermsTarget;
        let errorMessageWrapper = this.errorMessageWrapperTarget;

        let emailAddress = emailAddressField.value;
        let firstName = firstNameField.value;
        let lastName = lastNameField.value;
        let serviceTerms = serviceTermsField.checked;
        let isError = false;

        if (!this.isEmail(emailAddress)) {
            errorMessageWrapper.classList.remove('d-none');
            errorMessageWrapper.innerText = 'Sie müssen eine korrekte E-Mail Adresse angeben!';
            emailAddressField.classList.add('is-invalid');
            isError = true;
        } else {
            emailAddressField.classList.remove('is-invalid');
            emailAddressField.classList.add('is-valid');
            emailAddressField.classList.add('text-body');
        }
        if (!this.isNotEmpty(firstName)) {
            errorMessageWrapper.classList.remove('d-none');
            errorMessageWrapper.innerText = 'Sie müssen einen Vornamen angeben!';
            firstNameField.classList.add('is-invalid');
            isError = true;
        } else {
            firstNameField.classList.remove('is-invalid');
            firstNameField.classList.add('is-valid');
            firstNameField.classList.add('text-body');
        }
        if (!this.isNotEmpty(lastName)) {
            errorMessageWrapper.classList.remove('d-none');
            errorMessageWrapper.innerText = 'Sie müssen einen Nachnamen angeben!';
            lastNameField.classList.add('is-invalid');
            isError = true;
        } else {
            lastNameField.classList.remove('is-invalid');
            lastNameField.classList.add('is-valid');
            lastNameField.classList.add('text-body');
        }
        if (!serviceTerms) {
            errorMessageWrapper.classList.remove('d-none');
            errorMessageWrapper.innerText = 'Sie müssen den AGB und den Datenschutzbestimmungen zustimmen!';
            serviceTermsField.classList.add('is-invalid');
            isError = true;
        } else {
            serviceTermsField.classList.remove('is-invalid');
            serviceTermsField.classList.add('is-valid');
            serviceTermsField.classList.add('text-body');
        }

        if (!isError) {
            this.quickRegistrationFormTarget.submit();
            this.displaySubmitMessage();
        }
    }

    displaySubmitMessage() {
        this.googleButtonElement.classList.add('d-none');
        this.titleConfirmationElement.classList.remove('d-none');
        this.titleSearchtermElement.classList.add('d-none');
        this.modalTarget.querySelector('.modal-body').classList.add('text-center');
        this.quickRegistrationSubmitMessageTarget.classList.remove('d-none');
        this.formContentElement.classList.add('d-none');
        this.quickRegistrationSubmitMessageTarget.querySelector('#quick-registration-submit-message-email').innerHTML = this.quickRegistrationEmailAddressTarget.value;
    }

    handleModalOpen(event) {
        if (event.target.matches('[data-role="modal-trigger"]') && !this.active) {
            this.quickRegistrationEmailAddressTarget.value = '';
            this.quickRegistrationEmailAddressTarget.classList.remove('is-valid');

            this.quickRegistrationFirstNameTarget.value = '';
            this.quickRegistrationFirstNameTarget.classList.remove('is-valid');

            this.quickRegistrationLastNameTarget.value = '';
            this.quickRegistrationLastNameTarget.classList.remove('is-valid');

            this.quickRegistrationServiceTermsTarget.checked = false;
            this.quickRegistrationServiceTermsTarget.classList.remove('is-valid');

            this.errorMessageWrapperTarget.classList.add('d-none');

            this.show();
        }
    }

    // NOTE: In order to show and hide the Bootstrap modal properly, we need to use
    // jQuery.

    show() {
        if (this.jqueryExists) {
            jQuery('#jobseekerQuickRegistration').modal('show');
        } else {
            this.modalTarget.classList.add('show');
            this.modalTarget.classList.add('visible');
            this.modalTarget.classList.add('show');
            this.modalTarget.style.display = 'block';
        }
        this.active = true;
    }

    hide() {
        if (this.jqueryExists) {
            jQuery('#jobseekerQuickRegistration').modal('hide');
        } else {
            this.modalTarget.classList.remove('show');
            this.modalTarget.classList.remove('visible');
            this.modalTarget.classList.remove('show');
            this.modalTarget.style.display = 'none';
        }
        this.active = false;
    }
}
