import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["text"];

    connect() {
        this.truncateText()
    }

    truncateText() {
        const maxLength = parseInt(this.textTarget.dataset.maxLength);
        const element = this.element;
        const elementText = element.innerText;
        if (elementText.length > maxLength) {
            element.innerText = elementText.substring(0, maxLength) + '...';
            element.title = elementText;
        }
    }
}