import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['card', 'checkbox', 'copybtn']

    connect() {
        if (this.hasCheckboxTarget) {
            this.checkboxTarget.addEventListener('change', this.toggle.bind(this));
        }
        document.addEventListener('select:clear', this.handleClear.bind(this));
        document.addEventListener('select:all', this.handleSelectAll.bind(this));
        document.addEventListener('max-select', this.handleMax.bind(this));
    }

    disconnect() {
        if (this.hasCheckboxTarget) {
            this.checkboxTarget.removeEventListener('change', this.toggle.bind(this));
        }
        document.removeEventListener('select:clear', this.handleClear.bind(this));
        document.removeEventListener('select:all', this.handleSelectAll.bind(this));
        document.removeEventListener('max-select', this.handleMax.bind(this));
    }

    toggle(event) {
        const recurrentJobId = event.target.dataset.recurrentJobId;
        const joboffererProfileId = event.target.dataset.joboffererProfileId;

        if (event.target.checked) {
            this.styleSelectedCard();
        } else {
            this.styleDeselectedCard();
        }

        const updateEvent = new CustomEvent(
            'select:update', {
                detail: {
                    selected: event.target.checked,
                    recurrentJobId,
                    joboffererProfileId
                }
            });
        document.dispatchEvent(updateEvent);
    }

    handleClear() {
        if (this.hasCheckboxTarget && this.checkboxTarget.checked) {
            this.checkboxTarget.checked = false;
            this.styleDeselectedCard();
        }
    }

    handleMax(event) {
        if (event.detail.isMax) {
            jQuery('#too-many-chosen').modal();
            if (!this.checkboxTarget.checked) {
                this.checkboxTarget.disabled = true;
            }
        } else {
            if (!this.checkboxTarget.checked) {
                this.checkboxTarget.disabled = false;
            }
        }
    }

    copy(event) {
        const successElement = this.copybtnTarget.querySelector('[data-id="copy-success"]');
        const copyElement = this.copybtnTarget.querySelector('[data-id="copy-button"]');

        const phoneNumber = event.params.phone;
        const type = "text/plain";
        const blob = new Blob([phoneNumber], {type});
        const data = [new ClipboardItem({[type]: blob})];

        navigator.clipboard.write(data).then(function () {
            successElement.classList.remove('tw-hidden');
            successElement.classList.add('tw-flex');
            copyElement.classList.add('tw-hidden');
            copyElement.classList.remove('tw-flex');
            setTimeout(function () {
                successElement.classList.remove('tw-flex');
                successElement.classList.add('tw-hidden');
                copyElement.classList.add('tw-flex');
                copyElement.classList.remove('tw-hidden');
            }, 2000)

        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    handleSelectAll() {
        if (this.hasCheckboxTarget) {
            this.checkboxTarget.checked = true;
            this.styleSelectedCard()
        }
    }

    styleSelectedCard() {
        this.cardTarget.classList.add("tw-border-2");
        this.cardTarget.classList.add("tw-border-solid");
        this.cardTarget.classList.add("tw-border-joboo-gray-default");
    }

    styleDeselectedCard() {
        this.cardTarget.classList.remove("tw-border-2");
        this.cardTarget.classList.remove("tw-border-solid");
        this.cardTarget.classList.remove("tw-border-joboo-gray-default");

    }
}