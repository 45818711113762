import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ['content', 'allSwitch', 'icon', 'info', 'infoTrigger'];

    toggleWorkTimes() {
        this.allSwitchTarget.classList.toggle('tw-flex');
        this.allSwitchTarget.classList.toggle('tw-hidden');
        this.iconTarget.style.rotate = this.iconTarget.style.rotate === '180deg' ? '0deg' : '180deg';
        this.contentTarget.classList.toggle("tw-h-0");
        this.contentTarget.classList.toggle("tw-hidden");
        this.contentTarget.classList.toggle("tw-flex");
    }
}