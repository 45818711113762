import {Controller} from '@hotwired/stimulus';
import {getComponent} from '@symfony/ux-live-component';

export default class extends Controller {
    static targets = ['input', 'dropdown', 'error'];

    async initialize() {
        this.component = await getComponent(this.element);
        window.addEventListener('form:submitError', this.handleSubmitError.bind(this));
    }

    connect() {
        this.inputTarget.addEventListener('change', this.handleInputChange.bind(this));
        const isOpen = this.dropdownTarget.classList.contains('tw-flex');
        if (isOpen) {
            this.inputTarget.focus();
        }
        this.dropdownTarget.addEventListener('click', this.handleSelect.bind(this));
        this.dropdownTarget.addEventListener('click:outside', this.handleClickOutside.bind(this));
    }

    disconnect() {
        this.inputTarget.removeEventListener('click', this.handleInputChange.bind(this));
        this.dropdownTarget.removeEventListener('click', this.handleSelect.bind(this));
        this.dropdownTarget.removeEventListener('click:outside', this.handleClickOutside.bind(this));
    }


    handleInputChange(evt) {
        const isOpen = this.dropdownTarget.classList.contains('tw-flex');
        if (!isOpen) {
            this.dropdownTarget.classList.add('tw-flex');
            this.dropdownTarget.classList.remove('tw-hidden');
        }
    }

    handleSubmitError(evt) {
        if (Array.isArray(evt.detail) && evt.detail.length > 0) {
            evt.detail.forEach((error, i) => {
                const fieldName = error.name;
                const errorMessage = error.message;
                const componentFieldName = this.component.valueStore.get('name');
                if (fieldName === componentFieldName) {
                    this.component.valueStore.set('hasError', true);
                    this.errorTarget.innerText = errorMessage;
                    this.errorTarget.classList.add('tw-flex');
                    this.errorTarget.classList.remove('tw-hidden');
                    this.inputTarget.classList.add('tw-border-red-500', 'tw-text-red-600', 'focus:tw-outline-0');
                    this.inputTarget.classList.remove('tw-border-gray-200', 'focus:tw-outline-joboo-info-default');
                    if (i === 0) {
                        this.inputTarget.focus();
                    }
                }
            });
        }
    }

    handleSelect() {
        const isOpen = this.dropdownTarget.classList.contains('tw-flex');
        if (isOpen) {
            this.dropdownTarget.classList.remove('tw-flex');
            this.dropdownTarget.classList.add('tw-hidden');
        }
    }

    handleClickOutside() {
        const selectedItemIndex = this.component.valueStore.get('selectedItemIndex');
        const name = this.inputTarget.getAttribute('name');
        this.component.action('onSelect', {index: 0});
    }
}
