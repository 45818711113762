import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["dropdown", "address"];
    active = false;

    connect() {
        document.addEventListener("click", this.handleOutsideClick.bind(this));
        document.addEventListener('keydown', this.handleKeyPress.bind(this));
    }

    disconnect() {
        document.removeEventListener('click', this.handleOutsideClick.bind(this))
        document.removeEventListener('keydown', this.handleKeyPress.bind(this))
    }

    toggle() {
        if (this.active) {
            this.hide()
        } else {
            this.show()
        }
    }

    show() {
        this.dropdownTarget.classList.add("tw-flex");
        this.dropdownTarget.classList.remove("tw-hidden");
        this.active = true
    }

    hide() {
        this.dropdownTarget.classList.add("tw-hidden");
        this.dropdownTarget.classList.remove("tw-flex");
        this.active = false
    }

    handleOutsideClick(event) {
        if (this.active && this.element.contains(event.target) === false) {
            this.hide();
            const e = new CustomEvent('click:outside');
            this.dropdownTarget.dispatchEvent(e);
        }
    }

    handleKeyPress(event) {
        if (event.key === 'Escape') {
            if (this.active) {
                this.hide();
            }
        }
    }
    select(event) {
        const selectedContact = event.currentTarget.dataset.contactName;
        const splitContact = selectedContact.split('-');
        const name = splitContact[0];
        const address = splitContact[1];
        const row = event.currentTarget.closest('tr');
        if (row) {
            const textareaName = row.querySelector('textarea[name="application_appointment_scheduling_update_form[appointmentContactName]"]');
            const textareaAddress = row.querySelector('textarea[name="application_appointment_scheduling_update_form[appointmentAddress]"]');
            if (textareaName) {
                textareaName.value = name;
                textareaName.dispatchEvent(new Event('input', {bubbles: true}));
            }
            if (textareaAddress) {
                textareaAddress.value = address;
                textareaAddress.dispatchEvent(new Event('input', {bubbles: true}));
            }
        }
        this.dropdownTarget.classList.add('tw-hidden');
    }
}