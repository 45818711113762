import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        "vorname",
        "email",
        "error",
        "form",
        "submitButton",
        "formAlert",
        "activateJobradarButtonForUser"
    ];

    static values = {
        submitUrl: String,
        searchterm: String,
        searchParameters: String
    }

    connect() {
        if (this.hasActivateJobradarButtonForUserTarget) {
            this.activateJobradarButtonForUserTarget.addEventListener('click', this.activateJobradarButtonClickedByUser.bind(this));
        }
    }

    async activateJobradarButtonClickedByUser(event) {
        this.activateJobradarButtonForUserTarget.disabled = true;
        this.activateJobradarButtonForUserTarget.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp; Wird gestartet...';

        let data = {
            'searchterm': this.searchtermValue,
            'searchParameters': this.searchParametersValue
        };

        const response = await fetch(this.submitUrlValue, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json'
            }
        });

        const responseText = await response.text();
        let message = 'Jobradar wurde erfolgreich aktiviert!';
        if (!response.ok) {
            message = 'Bei der Erstellung des Jobradars ist ein Fehler aufgetreten!';
        }

        this.activateJobradarButtonForUserTarget.innerHTML = message;
        await this.sleep(5000);
        this.activateJobradarButtonForUserTarget.remove();
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async submit(event) {
        event.preventDefault();
        let result;

        if (!this.validateForm()) {
            return;
        }

        let modalTitle = document.getElementById('activate-jobradar-title');
        let modalBody = document.getElementById('activate-jobradar-body-message');
        let modalForm = document.getElementById('activate-jobradar-form');
        let modalBackButton = document.getElementById('activate-jobradar-back-button');

        // Button deaktivieren und Loading-Status setzen
        this.submitButtonTarget.disabled = true;
        this.submitButtonTarget.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Wird aktiviert...';

        const formData = new FormData(this.formTarget);
        formData.append('searchterm', this.searchtermValue);
        formData.append('searchParameters', this.searchParametersValue);

        const response = await fetch(this.submitUrlValue, {
            method: 'POST',
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json'
            }
        });

        const responseText = await response.text();

        if (!response.ok || responseText) {
            try {
                result = JSON.parse(responseText);
            } catch (e) {
                console.error('Response ist kein valides JSON:', responseText);
                throw new Error('Ungültige Server-Antwort');
            }
        }

        this.submitButtonTarget.disabled = false;
        this.submitButtonTarget.innerHTML = 'Job-Radar aktivieren!';

        if (result.message !== undefined && result.message !== '') {
            this.showError(result.message);
            return;
        }

        modalForm.innerHTML = '';
        modalTitle.innerText = 'E-Mail bestätigen';
        modalBackButton.classList.remove('d-none');
        modalBody.classList.remove('d-none');

        modalBackButton.onclick = function () {
            if (typeof window.jQuery !== 'undefined') {
                jQuery('#activate-jobradar').modal('hide');
            }
        };

        let activateJobradarButton = document.getElementById('activate-jobradar-button');
        activateJobradarButton.remove();
    }

    validateForm() {
        this.clearError();
        let isValid = true;

        const vornameValue = this.vornameTarget.value;
        const vornameRegex = /^[A-Za-zäöüÄÖÜß\-]{2,}$/;
        if (!vornameRegex.test(vornameValue)) {
            this.showError('Bitte gib einen gültigen Vornamen ein (nur Buchstaben, mindestens 2 Zeichen).');
            isValid = false;
        }

        const emailValue = this.emailTarget.value;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(emailValue)) {
            this.showError('Bitte gib eine gültige E-Mail-Adresse ein.');
            isValid = false;
        }

        return isValid;
    }

    showError(message) {
        this.formAlertTarget.innerHTML = message;
    }

    clearError() {
        this.vornameTarget.classList.remove('is-invalid');
        this.emailTarget.classList.remove('is-invalid');
        const errors = this.element.querySelectorAll('.text-danger');
        errors.forEach(error => error.remove());
    }
}