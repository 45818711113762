import {Controller} from '@hotwired/stimulus';

const EA_UPLOAD_FILES_FIELDS_ID = {
    PRIMARY_UPLOAD_FILE: 'primary_cv_uploaded_file',
    PRIMARY_DEFAULT_FILE: 'primary_cv_default_file',
    SECONDARY_UPLOAD_FILE: 'secondary_cv_uploaded_file'
};
export default class extends Controller {
    static targets = ['select', 'container', 'text'];

    connect() {
        this.id = this.selectTarget.getAttribute('id');
        this.selectTarget.addEventListener('input', this.onSelectFileChoice.bind(this));
        if (this.id === EA_UPLOAD_FILES_FIELDS_ID.PRIMARY_DEFAULT_FILE) {
            const useDefaultDocument = document.getElementById('extended_application_useDefaultDocument');
            const checked = useDefaultDocument.value === '1';
            this.selectTarget.checked = checked;
            this.style(checked);
        }
        if (this.id !== EA_UPLOAD_FILES_FIELDS_ID.SECONDARY_UPLOAD_FILE) {
            document.addEventListener('ea:select:file', this.handleChoiceSelected.bind(this));
        }
        const selector = this.containerTarget.dataset.fileInputSelector;
        if (selector) {
            const fileInput = document.querySelector(selector);
            fileInput.addEventListener('input', this.onUploadInputChange.bind(this));
        }
    }

    disconnect() {
        this.selectTarget.removeEventListener('input', this.onSelectFileChoice.bind(this));
        const selector = this.containerTarget.dataset.fileInputSelector;
        if (selector) {
            const fileInput = document.querySelector(selector);
            fileInput.removeEventListener('input', this.onUploadInputChange.bind(this));
        }
    }

    onSelectFileChoice(e) {
        const useDefaultDocumentInput = document.getElementById('extended_application_useDefaultDocument');
        const usePrimaryFile = document.getElementById('extended_application_useUploadedPrimaryFile');
        const useSecondaryFile = document.getElementById('extended_application_useUploadedSecondaryFile');

        if (useDefaultDocumentInput) {
            useDefaultDocumentInput.value = this.id === EA_UPLOAD_FILES_FIELDS_ID.PRIMARY_DEFAULT_FILE && e.target.checked ? 1 : 0;
        }
        if (this.id !== EA_UPLOAD_FILES_FIELDS_ID.SECONDARY_UPLOAD_FILE) {
            const selectFileChoiceEvent = new CustomEvent('ea:select:file', {
                detail: {
                    id: this.id
                }
            });
            document.dispatchEvent(selectFileChoiceEvent);
        }

        if (this.id === EA_UPLOAD_FILES_FIELDS_ID.PRIMARY_DEFAULT_FILE && e.target.checked) {
            usePrimaryFile.value = '0';
        }
        if (this.id === EA_UPLOAD_FILES_FIELDS_ID.PRIMARY_UPLOAD_FILE) {
            usePrimaryFile.value = e.target.checked ? '1' : '0';
        }

        if (this.id === EA_UPLOAD_FILES_FIELDS_ID.SECONDARY_UPLOAD_FILE) {
            useSecondaryFile.value = e.target.checked ? '1' : '0';
        }
        this.style(e.target.checked);
    }

    handleChoiceSelected(e) {
        if (e.detail.id !== this.id) {
            this.selectTarget.checked = false;
            this.style(false);
        }
    }

    onUploadInputChange(e) {
        const usePrimaryFile = document.getElementById('extended_application_useUploadedPrimaryFile');
        const useSecondaryFile = document.getElementById('extended_application_useUploadedSecondaryFile');
        const useDefaultDocument = document.getElementById('extended_application_useDefaultDocument');

        if (e.target.value) {
            if (this.id === EA_UPLOAD_FILES_FIELDS_ID.SECONDARY_UPLOAD_FILE) {
                useSecondaryFile.value = '1';
            }
            if (this.id === EA_UPLOAD_FILES_FIELDS_ID.PRIMARY_UPLOAD_FILE) {
                usePrimaryFile.value = '1';
                if(useDefaultDocument){
                    useDefaultDocument.value = '0';
                }

                const selectFileChoiceEvent = new CustomEvent('ea:select:file', {
                    detail: {
                        id: this.id
                    }
                });
                document.dispatchEvent(selectFileChoiceEvent);
            }
            if (this.id !== EA_UPLOAD_FILES_FIELDS_ID.PRIMARY_DEFAULT_FILE) {
                this.containerTarget.classList.add('tw-flex');
                this.containerTarget.classList.remove('tw-hidden');
                this.textTarget.innerHTML = e.target.value.split('\\').pop();
                this.selectTarget.checked = true;
                this.style(true);
            }
        }
    }

    style(isSelected) {
        const textClassToRemove = isSelected ? 'tw-text-gray-800' : 'tw-text-green-800';
        const textClassToAdd = isSelected ? 'tw-text-green-800' : 'tw-text-gray-800';

        this.textTarget.classList.remove(textClassToRemove);
        this.textTarget.classList.add(textClassToAdd);

        const borderClassToRemove = isSelected ? 'tw-border-gray-500' : 'tw-border-green-500';
        const borderClassToAdd = isSelected ? 'tw-border-green-500' : 'tw-border-gray-500';

        this.containerTarget.classList.remove(borderClassToRemove);
        this.containerTarget.classList.add(borderClassToAdd);
    }
}
